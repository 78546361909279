<template>
  <b-card>
    <!-- form -->
    <b-form @submit.prevent="changePassword">
      <b-row>
        <!-- new password -->
        <b-col md="4">
          <b-form-group
            label-for="account-current-password"
            label="Senha atual"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-current-password"
                v-model="currentPasswordValue"
                :type="passwordFieldTypeCurrent"
                name="current-password"
                placeholder="Senha atual"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconCurrent"
                  class="cursor-pointer"
                  @click="togglePasswordCurrent"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->
        <!-- new password -->
        <b-col md="4">
          <b-form-group label-for="account-new-password" label="Nova senha">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="Nova senha"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="4">
          <b-form-group
            label-for="account-retype-new-password"
            label="Repete a nova senha"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="RetypePassword"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="Repete a nova senha"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            type="submit"
          >
            Salvar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPasswordValue: "",
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeCurrent: "password",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconCurrent() {
      return this.passwordFieldTypeCurrent === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordCurrent() {
      this.passwordFieldTypeCurrent =
        this.passwordFieldTypeCurrent === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },

    async changePassword() {
      try {
        let data = {
          old_password: this.currentPasswordValue,
          password: this.newPasswordValue,
          password_confirmation: this.RetypePassword,
        };
        const response = await this.$store.dispatch(
          "account/updateUserPass",
          data
        );
        const { data: res } = response;
        this.profileFile = null;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `${res.message}`,
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
      } catch (e) {
        const { data } = e.response;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Erro ao atualizar dados!`,
            icon: "CoffeeIcon",
            variant: "red",
            text: data.error,
          },
        });
      }
    },
  },
};
</script>
